var $windowWidth = $(window).width(),
	$height = undefined;

function checkWidth() {
	if ( $windowWidth > 767 ) {
    	$height = $(window).height() - $('.navbar').outerHeight();
	} else {
		$height = 'auto';
	}
}

jQuery.fn.scrollTo = function (elem) {
    var b = $(elem);
    this.scrollTop(b.position().top + b.height() - this.height());
};

function checkAdresses(element) {
	if ( $(element).children('.js-create-new-address').is(':checked') ) {
		$(element).parents('.checkout-user').find('.addresses-create').addClass('addresses-create--active');
	} else {
		$(element).parents('.checkout-user').find('.addresses-create').removeClass('addresses-create--active');
	}
}

function checkSameAsShipping(element) {
	checkAdresses(element);
	if ( !$(element).children('input').is(':checked') ) {
		$(element).parents('.checkout-user').find('.checkout-notsame').addClass('checkout-notsame--active');
	} else {
		$(element).parents('.checkout-user').find('.checkout-notsame').removeClass('checkout-notsame--active');
	}
}

$(document).ready(function(){
	checkWidth();

    $('.item-minus').click(function () {
        var $input = $(this).parent().find('input');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;
    });


    $('.item-plus').click(function () {
        var $input = $(this).parent().find('input');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
    });


    $('.cart-remove').click(function () {
        var $cartRow = $(this).closest('.cart-row');
        $cartRow.hide("slow", function(){ $(this).remove(); })
        return false;
    });


    $('.js-search').click(function () {
        $('.search-toggle').toggle('fast');
    });


    $('.js-remove-saved-address').click(function () {
        var $cartRow = $(this).closest('.saved-address');
        $cartRow.hide("slow", function(){ $(this).remove(); })
        return false;
    });

    $('.js-height').css('height', $height);
	
	$('.how').on('click', '.js-toggle-how', function(){
	    $('.how').toggleClass('how--active');
	    $('.form--promo').toggleClass('how-active');

	    return false;
	});
	
	$('#modal-login').on('click', '.js-toggle-create-acc', function(){
		var $btn = $(this),
			$form = $('.create-form-mobile'),
			$modalContent = $(this).parents().find('.modal-content'),
			$modalHeight = $('.create-form-mobile').outerHeight();

	    $btn.toggleClass('btn-create--active');
	    $form.toggleClass('active');

		$('#modal-login').animate({
		    scrollTop: $('.create-form-mobile').parent().scrollTop() + $('.create-form-mobile').offset().top - $('.create-form-mobile').parent().offset().top
		}, {
		    duration: 500,
		    complete: function (e) {
		        console.log("animation completed");
		    }
		});

	    return false;
	});
	
	$('.navbar').on('click', '.js-toggle-hamburger', function(){
	    $(this).toggleClass('active');
	    $('.hamburger').toggleClass('hamburger--active');
	    $('body').toggleClass('hamburger--active');

	    return false;
	});

	$('#modal-login').on('show.bs.modal', function (e) {
	  
	  if ($('.js-toggle-hamburger').hasClass('active')) {
	  	$('.js-toggle-hamburger').trigger('click');
	  }
	});
	
	$(".js-show-add-new").each(function() {
	  checkAdresses($(this));
	});
	
	checkSameAsShipping($('.js-same-as-shipping'));
	
	$('.checkout-form').on('click', '.js-show-add-new', function(){
		checkAdresses($(this));
	});
	
	$('.js-edit-settings').on('click', function(){
		$(this).toggleClass('btn-disabled');
		$(".js-form-control").each(function() {
		  if ($(this).attr('disabled')) {
		  	$(this).removeAttr('disabled');
		  } else {
		  	$(this).attr('disabled', 'disabled');
		  }
		});
		$('.js-form-control').toggleClass('form-control-plaintext');
		$('.js-form-control').toggleClass('form-control');
	});
	
	// $('.js-form-control').on('focusout', function(){
	// 	$('.js-edit-settings').toggleClass('btn-disabled');

	// 	$(".js-form-control").each(function() {
	// 	  	$(this).attr('disabled', 'disabled');
	// 	});
		
	// 	$('.js-form-control').toggleClass('form-control-plaintext');
	// 	$('.js-form-control').toggleClass('form-control');
	// });
	
	$('.checkout-form').on('click', '.js-same-as-shipping', function(){
		checkSameAsShipping($(this));
	});
	
	$('.js-carousel').slick({
		slidesToShow: 5,
		dots: false,
		responsive: [
			{
			  breakpoint: 980,
			  settings: {
			  	dots: false,
				arrows: true,
				centerMode: false,
				centerPadding: 0,
				slidesToShow: 3
			  }
			},
			{
			  breakpoint: 560,
			  settings: {
				arrows: true,
				centerMode: false,
				centerPadding: 0,
				slidesToShow: 2
			  }
			}
		]
	});
});

$(window).on('resize', function(){
	checkWidth();
    $('.js-height').css('height', $height);
});